@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');



body {
  scroll-behavior: smooth;
  background-color:  #ffffff;
  color: black;
  /* letter-spacing: 2px; */
  font-family: "Lato", sans-serif;
 

}

nav {
  display: flex;
  margin: auto;
  padding: 5px 30px;
  /* justify-content: space-between; */
  align-items: center;
  background-color:   #fff9f0;
   
}
nav > h1 {
  /* color: #1c1c1c; */
  /* margin-right: 5px; */
}

#logo {
  width: 220px;
  align-items: center;
  text-align: center;
  height: 192px;
  /* margin: -5px; */
  margin: auto;
}



  

#main {
  text-align: center;
  margin-top: 30px;
}

.form {
  max-width: 650px;
  margin: auto ;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  /* background-color: rgb(251, 251, 251) ; */
  /* background-image: url(../public/bg-form.png);
  background-repeat: no-repeat;
  background-position: center; */
  /* color: #1c1c1c; */
  padding: 5% 3%;
  
}
#form-container{
  margin-top: 10PX;
  padding-top:40px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: flex;
  margin-left: 50px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 15px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="file"]  {
  width: 70%;
  padding: 10px;
  /* border: none; */
  border-radius: 4px;
  background-color: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.2s ease-in-out;
}
 select {
  width: 75%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="file"]:focus {
  outline: none;
  border-bottom: 3.2px solid black;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
  background-color: rgb(244, 244, 244);
  
}

input[type="number"]{
  scroll-behavior: none;
}
input[type="number"]:focus-within{
  scroll-behavior: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input{
  color: black;
  border: 1px solid black;
  border-bottom: 3px solid black;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

sup{
  color: red;
  font-size: 14px;
}

.btn {
  display: inline-block;
  font-size: 17px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: #0062cc;
}

#check {
  display: flex;
  align-items: center;
}

#check > input {
  margin-left: 50px;
}

#check > label {
  /* width: 100px; */
  /* padding-left: 5px; */
  margin-left: 0px;
  margin-right: 50px;
}

#modal {
  width: 50%;
  margin: auto;
  height: max-content;
  background-color: rgb(253, 253, 253);
  color: #232324;
  text-align: center;
  border-radius: 10px;
}

#modal > div {
  width: 60%;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: auto;
  letter-spacing: 1.35px;
  /* color: ; */
}
#modal > div > button {
  display: block;
text-align: center;
  width: max-content;
  margin: auto;
  margin: 15px auto;
  padding: 7px;
  background-color: #1d404b;
  color: #fff;
  font-size: 15px;
  letter-spacing: 2px;
  cursor: pointer;
}
#modal  > button {
  display: block;
text-align: center;
  width: max-content;
  margin: auto;
  margin: 15px auto;
  padding: 10px 15px;
  background-color: #1e5df0;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.35px;
  cursor: pointer;
  border: 0px solid black;
  border-radius: 10px;
}
#modal>div>p{
  font-size: 22px;
}

 

footer {
  /* border-top: 1px solid white; */
  margin-top: 7%;
  padding: 3% 0%;
  background-color: #f7f7f7;
}

footer > div > a {
  text-decoration: none;
  color: #1d404b;
  font-size: 17px;
  font-weight: 500;
  padding: 5px;
}
#tiles > a:hover {
  color: white;
  font-size: 17px;
  background-color: #28282b;
  /* padding: 5px; */
}

#tiles {
  display: flex;
  /* margin-top: 15px; */
  justify-content: space-evenly;
}

#disc {
  width: 80%;
  /* justify-content: center;
align-content: center; */
  margin: 100px auto;
  /* color: #4b5551; */
  letter-spacing: 1.3px;
  text-align: center;
  line-height: 2em;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffc008;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffc008;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover {
  background: white;
}

 
#hero-section, #mid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#about, #form-tag {
  /* color: #1c1c1c; */
  font-size: 17px;
  letter-spacing: 1.3px;
  width: 50%;
  margin: 50px auto;
  padding: 0px 10px;
  line-height: 35px;
}

#hero-img, #money-img{
  width: 40%;
  padding: 0px 40px;
}
#hero-img img, #money-img img{
  max-width: 100%;
  border-radius: 10px;
}

#form-container p{
font-size: 17px;
}

 
#dtd-span{
  text-decoration: 2px underline #dc3445;
  
}

.mailUs {
  display: flex;
  width: fit-content;
  font-size: 18px;
  margin: auto;
  justify-content: center;
}

/* .punch > span {
  color: #dc3445;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
} */

#tnc {
  justify-content: center;
  width: 85%;
  margin: auto;
  padding: 5%;
  background-color: #fff;
  color: #28282b;
  /* letter-spacing: 2.5px; */
  line-height: 47px;
}
.heading {
  width: max-content;
  margin: auto;
}

.welcome {
  text-align: center;
  font-size: 17px;
}
#tnc > p {
  font-size: 17px;
}

textarea{
   
  width: 70%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.2s ease-in-out;
  resize: vertical;
}
.form2{
  max-width: 400px;
  margin: 150px auto;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  background-color: #1d404b ;
  color: white;
  padding: 5% 3%;
  border-radius: 10px;
}

.feedBtn {
  display: block;
  padding: 10px 20px;
  width: max-content;
  margin: 20px auto;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.form2>label{
  padding-top: 10px;
}

footer{
  text-align: center;
}


a{
  text-decoration: none;
  /* color: #1c1c1c; */
  padding: 10px 20px;
  
  }
  
   



@media (max-width: 450px) {
  body {
    margin: 1px;
  }
  

  nav {
    padding: 5px;
    
  }

  nav > #logo {
    width: 50%;
    height: auto;
  }

  nav > button {
    width: 40%;
    height: max-content;
  }
  #tnc {
    width: 100vw;
    padding: 10%;
    margin: 0px;
  }
  .heading {
    width: max-content;
    margin: auto;
    word-break: break-all;
  }
  #tnc > p {
    margin: auto;
    word-wrap: break-word;
  }
  #tiles {
    margin-top: 20px;
  }

  .btn {
    margin: 15px;
  }
  #about {
    width: 90%;
  }
  #form-tag, #money-img,#hero-img{
    width: 100%;
  }
  label{
    margin: 5px 15px;
  }
  
}

@media (max-width: 620px) {
  label{
    margin: 5px 0px;
  }
  #modal {
    width: fit-content;
    margin: auto;
  }
  #modal > div {
    width: fit-content;
    margin: auto;
  }
  #modal > div > button {
    width: fit-content;
  }
  footer{
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1000px) {
  #hero-section{
    margin: 50px auto;
  }
 #hero-img{
  order: 1;
 }
 #about{
  order: 2;
 }
}

/* .punch > span:nth-child(1) {
  position: relative;
  animation-name: slide;
  animation-duration: 3s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.punch > span:nth-child(2) {
  position: relative;
  animation-name: slide;
  animation-duration: 3s;
  animation-delay: 2.3s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.punch > span:nth-child(3) {
  position: relative;
  animation-name: slide;
  animation-duration: 3s;
  animation-delay: 3.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.punch > span:nth-child(4) {
  position: relative;
  animation-name: slide;
  animation-duration: 3s;
  animation-delay: 4.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.punch > span:nth-child(5) {
  position: relative;
  animation-name: slide;
  animation-duration: 3s;
  animation-delay: 5.6s;
  animation-fill-mode: forwards;
  opacity: 0;

} */

@keyframes slide {
   
   

  to {
    /* top: -400px; */
    /* display: inline; */
    opacity: 1;
  
  }
 
}
 
 
